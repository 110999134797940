import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {ApiGlobal} from "../hooks/ApiGlobal";
import {Col, Button, Row, Rate, Divider} from "antd";
import { Fade, Slide } from "react-awesome-reveal";
import { Navigate,useNavigate } from "react-router-dom";


const Home =()=>{

  const navigate = useNavigate();

  const fetchdata = useSelector((state)=>state.fetchdata);
  const {getFetch} = ApiGlobal("",["productos"]);

  
  //Cargar lista de productos
  useEffect(()=>{
   getFetch("productos");
  },[]);

  //Abrir pagina de producto
  const gotoProduct=(id,tipo)=>{
    if (tipo ===1){
      navigate("producto/"+id);
    }else{
      navigate("editor/"+id);
    }
 
  }





  return (
    <div>
      <Row>
        <h2>Conoce Nuestros Productos</h2>
      </Row>
      <div className="products">
        {fetchdata.fetch.productos && fetchdata.fetch.productos.map((product, index) => (
          <Col
            key={product.id}
            span={12}
            sm={8}
            md={6}
            lg={6}
            xl={5}
            style={{ padding: "5px" }}
            onClick={() => gotoProduct(product.id, product.tipo)}
          >
            <Fade fraction={0.5} duration={800 + Math.cos(index / 2) * -200} direction="up" triggerOnce={true}>
              <div className="selectable">
                <div className="cardItem" style={{ borderRadius: "20px" }}>
                  <div style={{ margin: "0px", padding: "0px" }}>
                    <img
                      style={{ width: "100%" }}
                      className="coverimg"
                      src={product.img_url}
                      alt={product.name}
                    />
                  </div>
                  <h4>{product.p_name}</h4>
                  <br />
                  <p>{`$ ${product.p_base_price.toLocaleString()}`}</p>
                  <br />
                  <Rate
                    style={{
                      fontSize: "0.8rem",
                      margin: "0px",
                      padding: "0px",
                      display: "inline",
                      marginInlineEnd: "8px"
                    }}
                    allowHalf
                    defaultValue={5}
                  />
                  <br /><br />
                  {product.tipo === 1 ? (
                    <Button style={{ borderRadius: "30px" }} type="primary">Comprar</Button>
                  ) : (
                    <Button style={{ borderRadius: "30px", backgroundColor: "green" }} type="primary">Diseñar</Button>
                  )}
                  <br /><br />
                </div>
              </div>
            </Fade>
          </Col>
        ))}
      </div>
    </div>
  );
}

export default Home;
  