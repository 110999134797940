import React,{ useEffect,useState } from 'react';
import { Table, Button, Modal,Row,Col,Card,Image} from 'antd';
import Moment from 'moment';
import { MdCancel } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { ApiGlobal } from '../../hooks/ApiGlobal';

Moment.locale('es');
    

const Orders = () => {
  const api_url = process.env.REACT_APP_API+"preview/";
  const dispatch = useDispatch();
  const userdata = useSelector((state)=>state.user);
  const fetchdata = useSelector((state)=>state.fetchdata);
  const {getFetch} = ApiGlobal(userdata.auth_token,["orders"]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalContent, setModalContent] = useState(<></>);

  
  //Cargar Ordenes
  useEffect(()=>{
    getFetch("orders");
   },[]);


  const showModal = (url) => {


    setModalContent(<>
            <Row>
            <Col span={24} lg={12} style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
            <div style={{textAlign:"center",padding:"10px"}}>
            <p style={{fontSize:"22px"}}>Frente</p>
              <Image src={url+"/1"} />
            </div>
            </Col>
            <Col span={24} lg={12}>
            <div style={{textAlign:"center",padding:"10px"}}>
            <p style={{fontSize:"22px"}}>Atras</p>
            <Image src={url+"/2"} />
            </div>
            </Col>
        </Row>
    </>)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  
  function currencyFormat(num) {
    if (num){
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }else{
      return '';
    }
   
  }


  const get_products_format=(products)=>{
  let products_json = JSON.parse(products);

  const obj = Object.entries(products_json);

  return (
    <>

        {
           obj.map((item) => (
            item[1].id ? (<>
            <a target="_blank" href={"http://localhost:3000/producto/"+item[1].id}>{item[1].name}</a><span> {item[1].preview_design ? (<Button size="small" style={{fontSize:10}} onClick={()=>{showModal(api_url+item[1].preview_design)}}>Preview</Button>):(null)}</span>
            <p style={{fontSize:"10px",color:"gray"}}><i>{currencyFormat(item[1].price)} x {item[1].cartQuantity}</i></p>
            </>):(null)
           ))
        }
    </>
  );
}


const get_shipping_format=(shipping)=>{
  let shipping_json = JSON.parse(shipping);

  const obj = Object.entries(shipping_json);

  return (
    <>

        {
           obj.map((item) => (
            item[1].city ? (<>
            <p style={{fontSize:"10px",color:"gray"}}><i>{item[1].city+", "+item[1].state}</i></p>
            {item[1].line1 !="" ? (<p style={{fontSize:"10px",color:"gray"}}><i>{item[1].line1}</i></p>):(null)}
            {item[1].line2 !="" ? (<p style={{fontSize:"10px",color:"gray"}}><i>{item[1].line2}</i></p>):(null)}
            {item[1].postal_code !="" ? (<p style={{fontSize:"10px",color:"gray"}}><i>{item[1].postal_code}</i></p>):(null)}
            </>):(null)
           ))
        }
    </>
  );
}

const columns = [
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (id, record, index) => `${index+1}`
  },
  {
    title: 'Fecha',
    dataIndex: 'creation_date',
    key: 'creation_date',
    render: (fecha) => `${Moment(fecha).format('DD/MM/YYYY')}`
  },
  {
    title: 'Articulos',
    dataIndex: 'products',
    key: 'products',
    render: (products) => {
      return get_products_format(products);
    }

      
  
  },
  {
    title: 'Dirección',
    dataIndex: 'shipping',
    key: 'shipping',
    render: (shipping) => {
      return get_shipping_format(shipping);
    }

      
  
  },
  {
    title: 'Total($)',
    dataIndex: 'total',
    key: 'total',
    render: (total) => `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    
  },
  {
    title: 'Estatus',
    dataIndex: 'order_status',
    key: 'order_status',
    render: (status) => {
      if (status == 0){return "En espera";}else
      if (status == 1){return "Procesando";}else
      if (status == 2){return "En Camino";}else
      if (status == 3){return "Completado";}else
      if (status == -1){return "Pausa";}else
      if (status == -2){return "Cancelado";}else{
        return "Desconocido";
      }
    }
  },

];




  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });




    return (<>

      <div>
        <Table locale={{ emptyText: 'No hay ninguna orden para mostrar' }} dataSource={fetchdata.fetch.orders} columns={columns}  />
      </div>
        <Modal width="1200px" style={{ maxWidth:"98%"}} title="" closeIcon={<a style={{fontSize:"23px"}}><MdCancel /></a>} open={isModalOpen} footer={<></>} onOk={()=>{ setIsModalOpen(false);}} onCancel={()=>{ setIsModalOpen(false);}}>
        {ModalContent}
        </Modal>
        </>
    );
  };
  
  export default Orders;