import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../redux/cartSlice";

import { useNavigate } from "react-router-dom";
import { Col, Row, Card} from 'antd';
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import { MdLogin } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import { RiUser3Fill } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import React, { useState } from 'react';
import { Button, Drawer, Divider,Modal } from 'antd';
import Login from './Login';
import { FaPencilRuler } from "react-icons/fa";
import { BiSolidDog } from "react-icons/bi";
import { IoHomeSharp } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { AuthManager } from "../hooks/AuthManager";

function currencyFormat(num) {
  return '$' + Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const NavBar = () => {

  const userdata = useSelector((state)=>state.user);
  const cart = useSelector((state)=>state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const {
    getLogin,
    logout,
    register,
  } = AuthManager();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
 

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  return (
    <>
   
    {userdata.data.logged == false ? (
  <Modal width="1200px" style={{overflow:"hidden", maxWidth:"98%"}} title="" closeIcon={<a style={{fontSize:"23px"}}><MdCancel /></a>} open={isModalOpen} footer={<></>} onOk={()=>{ setIsModalOpen(false);}} onCancel={()=>{ setIsModalOpen(false);}}>
        {userdata.data.logged ? (<></>): (<>

          <Row>
          <Col span={24} lg={13} className="loginwallpaper">

            </Col>
            <Col span={24} lg={13} style={{justifyContent:"center", alignItems:"center",display:"flex"}}>
            <div style={{textAlign:"center",padding:"30px"}}>
            <h2 style={{fontSize:"32px",color:"white"}}>Accede a un portal para el seguimiento de tus pedidos y contenido especial</h2><br/>
            </div>
            </Col>
            <Col span={0} lg={1}></Col>
            <Col span={24} lg={10}>
            <br/> <br/>
     <Card>
             <Login/>
             </Card>
            </Col>
        </Row></>)}
  </Modal>):(null)
}

    <Row  className="nav-bar" style={{position:"fixed", width:"100%",zIndex:"20", alignItems:"right", backgroundColor:"white"}}>
     <Col span={12}  md={10} xl={5} style={{padding:"10px"}}>
      <a href="/"><img src="/assets/images/mascody_logo.webp" width="200px" /></a>
    </Col>
    <Col span={0} xl={11} style={{alignItems:"right"}}>
    <ul style={{marginLeft:"0px"}}>
    <li><span className="selectable" ><a style={{display: 'flex',alignItems: 'right',justifyContent: 'right'}} className="navlink" href="/"><IoHomeSharp />&nbsp; Inicio </a></span></li>
      <li><span className="selectable" ><a style={{display: 'flex',alignItems: 'right',justifyContent: 'right'}} className="navlink" href="/tienda"><BiSolidPurchaseTagAlt />&nbsp; Tienda </a></span></li>
      <li><span className="selectable" ><a style={{display: 'flex',alignItems: 'right',justifyContent: 'right', paddingLeft:"16px"}} className="navlink" href="/personalizar/51"><FaPencilRuler />&nbsp; Personalizar</a></span></li>
      <li><span className="selectable" ><a style={{display: 'flex',alignItems: 'right',justifyContent: 'right', paddingLeft:"10px"}} className="navlink" href="/nosotros"><span style={{fontSize:"22px",height:"0px",marginTop:"-4px", lineHeight:"0px"}}><BiSolidDog /></span>&nbsp; Nosotros</a></span></li>
    </ul>
    </Col>
    <Col span={0} md={12} xl={7}>
    <ul style={{display: 'flex',alignItems: 'right',justifyContent: 'right',marginRight:"20px"}}>
      <li style={{paddingRight:"26px"}}>
        <span className="selectable" >
        { userdata.data.logged ? (<>
        <a style={{display: 'flex',alignItems: 'right',justifyContent: 'right'}} className="navlink" href="/cuenta"><RiUser3Fill />&nbsp; Mi Cuenta</a>
        </>):(<>
          <a style={{display: 'flex',alignItems: 'right',justifyContent: 'right'}} className="navlink" onClick={showModal}><MdLogin />&nbsp; Iniciar Sesion</a>
        </>)}
        
        </span></li>
      <li><span className="selectable" ><a style={{display: 'flex',alignItems: 'left',justifyContent: 'left'}} className="navlink"  href="/cart">
            <span style={{height:"0px",position:"absolute",marginTop:"-5px",marginLeft:"-35px",fontSize:"24px"}}>
                <FaShoppingCart />
                {cart.cantidad > 0 ? (
                          <span style={{marginTop:"-13px",marginLeft:"-10px",position:"absolute",textAlign:"center",color:"rgb(87,45,34)"}} className="bag-quantity">
                          {cart.cantidad}
                          </span>) : <></>}
              </span> Mi Carrito
          </a></span>
      </li>
     
    </ul>
    </Col >
    <Col xl={1}>

      <a className="navlink" style={{fontSize:"26px",alignItems: 'right',justifyContent: 'right',border:"1px solid #A3B1E7",padding:"8px 8px 0px 8px",borderRadius:"5px"}} onClick={showDrawer}><GiHamburgerMenu /></a>

    </Col>
    </Row>

            <Drawer
              title={userdata.data.logged ? ("Mi Cuenta"):("")}
              placement="right"
              width={500}
              onClose={onClose}
              open={open}

            >
               <>
              {
               
                  userdata.data.logged ?(

                    <>
                    <div className="profilemenu" style={{fontSize:"1rem"}}>

                    <Row gutter={20}>
                      <Col>
                      <span style={{fontSize:"72px",color:"#9EACE3"}}><FaUserCircle /></span>
                      </Col>
                      <Col>
                        <p className="profilemenu_name"><b>{userdata.data.name}</b></p>
                        <p className="profilemenu_email">{userdata.data.email}</p>    
                        <Button style={{marginTop:"5px"}} onClick={()=>{logout()}}>Cerrar sesion</Button>
                      </Col>
                    </Row>
                   
                    
                    </div>

                 

                    <a style={{marginTop:"20px"}} className="navsub" href="/cuenta">Mi Cuenta</a>
      


                    {userdata.data.u_type == 2 ?(
                    <>
                      <Divider>Admin</Divider>
                      <a className="navsub" href="/">Dashboard</a>
                    </>
                    ):(<></>)}


                    
                    </>
                    

                  ):(
                    <>
                       <Button type="primary" block onClick={()=>{ setOpen(false); showModal();}}><MdLogin />&nbsp; Iniciar Sesion</Button><br/><br/>
                       <Button block onClick={()=>{ navigate("/register"); setOpen(false); }}><FaUserPlus/>&nbsp; Registrarse</Button>
                       <br/><br/>
                  
                    </>
                  )

              }
              <Divider>Enlaces</Divider>
                 <a className="navsub" href="/">Inicio</a>
                 <a className="navsub" href="/">Tienda</a>
                 <a className="navsub" href="/">Personalizar</a>
                 <a className="navsub" href="/">Nosotros</a>
                 <a className="navsub" href="/">Terminos y Condiciones</a>
                 <a className="navsub" href="/">Preguntas Frecuentes</a>
                  </>
                <Button type="primary" style={{position:"absolute", left:"-8px", height:"85px",textAlign:"left",bottom:"-10px",width:"550px"}} href="/cart">
                  <Row style={{paddingLeft:"50px"}}>
                      <Col style={{fontSize:"2.2rem"}}>
                        <FaShoppingCart />
                      </Col>
                      <Col style={{paddingLeft:"20px", display:"block"}}>
                      <p style={{fontSize:"1.2rem",display:"inherit"}}><b>Ir a Pagar</b></p>
                      <p style={{fontSize:"0.9rem",display:"inherit"}}>{currencyFormat(cart.subtotal)} |  {cart.cantidad} x articulos</p>
                      </Col>
                  </Row>   
                </Button>
            </Drawer>
           
</>

  );
};

export default NavBar;
  