import { useState, useEffect} from 'react';
import {Poster} from './Poster';
import dayjs from 'dayjs';
import { setauthtoken,setUser,setauthloading} from '../redux/userSlice.js';
import { useDispatch } from 'react-redux';


const poster= new Poster();

export function AuthManager(){

    const dispatch = useDispatch();

    const getuserdata = async(token) => {
        
        let res = false;

        try{
            dispatch(setauthloading(true));
            dispatch(setUser({
                name: "",
                fullname: "",
                email: "",
                logged: false
            }));

            let response = false;

            response = await poster.post("auth/userdata","POST",[],token);
        
         

            if (response){
                if (response.name){
                dispatch(setUser(response));
                dispatch(setauthtoken(token));
                res = true;
            }}
            


            
    
        }catch(ErrorAuth){
            //setErrorAuth(ErrorAuth);
        } finally{
            dispatch(setauthloading(false));

            if (res == false){
                localStorage.removeItem("tokenses");
                dispatch(setauthtoken(""));
                dispatch(setUser({
                    name: "",
                    fullname: "",
                    email: "",
                    logged: false
                }));
                
            }
        }
        return res;
    }


    const getLogin = async(post_data) => {
        
        let res = false;

        try{
            dispatch(setauthloading(true));
            dispatch(setUser({
                name: "",
                fullname: "",
                email: "",
                logged: false
            }));

            let login_type = 1; //normal
            if (post_data.credentialResponse){
                if (post_data.logintype == 2){
                    login_type = 2; //Iniciar sesion con Google
                }
            }

            let response = false;

            if (login_type==1){
                response = await poster.post("auth/login","POST",post_data);
            }else if (login_type==2){
                response = await poster.post("auth/login/conGoogle","POST",{credential:post_data.credentialResponse.credential,clientId:post_data.credentialResponse.clientId});
            }
            

            if (response.name){
    
                dispatch(setUser(response));
                res = true;
                localStorage.setItem("tokenses",response.auth_token);
            }else{
                //setErrorAuth("Correo o Contraseña Invalidos");
            }
            
    
        }catch(ErrorAuth){
            //setErrorAuth(ErrorAuth);
        } finally{
            dispatch(setauthloading(false));
        }


        return res;
    };

    const register = async(post_data) => {
        let res = false;
        try{
           // setErrorAuth("");
            dispatch(setauthloading(true));
            dispatch(setUser({
                name: "",
                fullname: "",
                email: "",
                logged: false
            }));

            const response = await poster.post("auth/register","POST",post_data);

            if (response.token){
                //setErrorAuth("");
                dispatch(setUser(response));
                res = true;
                localStorage.setItem("loginses",JSON.stringify(response));
            }else{
                //setErrorAuth("Registro no valido, prueba con otro correo o contacta al administrador");
            }

        }catch(ErrorAuth){
            //setErrorAuth(ErrorAuth);
        } finally{
            dispatch(setauthloading(false));
        }
        return res;
    };

    const logout = async(post_data) => {
        localStorage.removeItem("tokenses");

        dispatch(setUser({
            name: "",
            fullname: "",
            email: "",
            logged: false
        }));

    };

    return {
        getuserdata,
        getLogin,
        logout,
        register,
    }

}

