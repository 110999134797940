import {configureStore} from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import cartReducer from "./cartSlice";
import fetchSlice from "./fetchSlice";

export const globalstate = configureStore({

    reducer:{
        user:userReducer,
        cart:cartReducer,
        fetchdata:fetchSlice,
    }

});
