import React, { useEffect } from 'react';
import { Tabs, Col, Row} from 'antd';
import { MdLocalShipping } from "react-icons/md";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { IoBarChart } from "react-icons/io5";
import { MdLocalPostOffice } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
//DASHBOARD
import Orders from "./Cuenta/Orders";
/*
import Products from "./Products";
import Summary from "./Summary";
*/
import Perfil from "./Cuenta/Perfil";


const Cuenta = () => {

  const userdata = useSelector((state)=>state.user);
  const dispatch = useDispatch();

  useEffect( () =>{
    ( async() => {
      if (userdata.data.logged){
      
     //await getOrders(0,userdata.data.token);
      }
    })()
  }, []);
  


  if (!userdata.data.logged){
    return "Acceso Denegado"
  }


const items = [
  {
    key: '1',
    label: <p style={{fontSize:"18px"}}><MdLocalShipping /> Ordenes</p>,
    children: <Orders/>,
  },
  {
    key: '2',
    label: <p style={{fontSize:"18px"}}><FaUserAlt /> Perfil</p>,
    children: <Perfil/>,
  },
  {
    key: '3',
    label: <p style={{fontSize:"18px"}}><MdLocalPostOffice /> Solicitudes</p>,
    children: <Perfil/>,
  },


];

if (userdata.data.logged == 2){
  items.push(
    {
      key: '4',
      label: <p style={{fontSize:"20px"}}><BiSolidPurchaseTag /> Productos</p>,
      children: <Orders/>,
    },
    {
      key: '5',
      label: <p style={{fontSize:"20px"}}><IoBarChart /> Dashboard</p>,
      children: <Orders/>,
    }
  );
}


  return <div style={{paddingTop:"80px"}}>
    <Row>
      <Col span={24} style={{alignContent:"center",textAlign:"center"}}>

        <div>
        <h1>Mi Cuenta</h1>
        <p>Revisa el estatus de sus pedidos, actualiza tu información o reporta cualquier problema</p>
        </div>
        <br/><br/><br/>
     
      </Col>
   
    </Row>
  <Tabs tabBarGutter={"10vw"} centered style={{width:'100%'}} defaultActiveKey="1" items={items}  />
  </div>

}


export default Cuenta;
  
  