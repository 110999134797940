import React, { useEffect, useRef, useState,updateState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router";
import { Button, Tabs, Rate, Col, Row,Dropdown,  Modal, Upload, message, Select,Spin } from 'antd';
import { FaShoppingCart } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from 'react-redux';
import { ApiGlobal } from '../hooks/ApiGlobal';
import { addItem } from '../redux/cartSlice';
import * as fabric from 'fabric'; // v6
import { useCallback } from 'react';
import { FaEdit } from "react-icons/fa";
import Draggable from "../components/Draggable.jsx";
import {setfetchdata, setitemdata, setstatus} from '../redux/fetchSlice.js';
import { IoText } from "react-icons/io5";
import { FaStar } from "react-icons/fa6";
import { FaFileUpload } from "react-icons/fa";
import { BsFront } from "react-icons/bs";
import { BsBack } from "react-icons/bs";



export const Editor = () => {


  const dispatch = useDispatch();
  let globalBg = null;

  const fetchdata = useSelector((state)=>state.fetchdata);
  const cart = useSelector((state)=>state.cart);
  const [messageApi, contextHolder] = message.useMessage();
  const [ladoSeleccionado,setladoSeleccionado] = useState(1);
  
  const [SavingImage,setSavingImage] = useState(false);

  const { id } = useParams();
  const endpoint = "producto/"+id;
  const {getFetch} = ApiGlobal("123",[endpoint]);
  
  let product_data = fetchdata.fetch[endpoint] ? fetchdata.fetch[endpoint].data:[];
  useEffect(()=>{
    getFetch(endpoint);
  },[]);







  const [modalProps, setmodalProps] = useState({
    title:<></>,
    content:<></>,
    active: false
  });
  



  
  const [ref, setref] = useState({active:false});
  const [canvas, setcanvas] = useState([]);
  let canvas_def = null;

  //let canvas = {};



  const navigate = useNavigate();


  const handleAddToCart = (preview_design = "") => {

    const product_cart = {
        id: product_data.id,
        p_name: product_data.p_name,
        p_base_price: product_data.p_base_price,
        p_shipping_price_base: product_data.p_shipping_price_base,
        tipo: product_data.tipo,
        p_short_desc: product_data.p_short_desc,
        preview_design: preview_design
    }

    dispatch(addItem({...product_cart, image: fetchdata.fetch[endpoint].images[0].thumbnail, unique: true }));
    navigate("/cart");
  };




  const items = [
    {
      key: '1',
      label: 'Descripción',
      children: fetchdata.status[endpoint] ? product_data.p_long_desc : <></>,
    },
    {
      key: '2',
      label: 'Información extra',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Valoraciones',
      children: 'Content of Tab Pane 3',
    },
  ];




  let canvasProps = {
    width: 800,//window.innerWidth,
    height: window.innerHeight
  }

  const canvasEl = useRef();


//MASK

const clipRect = new fabric.Rect({
  originX: 'left',
  originY: 'top',
  left: 180,
  top: 10,
  width: 200,
  height: 200,
  fill: 'red', 
  selectable: false
});






const renderTools=()=>{

  

  return [ ref.active ?
    
    (<div style={{left:"5vw",top:"5vh",minHeight:"400px",width:"400px",backgroundColor:"rgba(255,255,255,0.6)",position:"absolute", padding:"20px",borderRadius:"10px", boxShadow:"4px 4px 7px 7px rgba(0,0,0,0.2)"}}>
    <p style={{fontSize:"18px"}}>Propiedades</p><br/>
    <Row>
      <Col span={24}>
      <p>Fuente:</p>
        
        <Dropdown menu={{ items: font_list }}  trigger={['click']}>
            <Button block><span style={{ fontFamily:ref.fontFamily}}>{ref.fontFamily} </span><FaEdit /></Button>
        </Dropdown>

      </Col>
    </Row>
    <br/><br/><br/>
    {JSON.stringify(ref,null,2)}
  </div>):null

  ]

}

const addMask=async(cv)=>{

  const xclipRect = await fabric.FabricImage.fromURL(
    "http://localhost:3000/assets/images/editor/spr_design12.png",
    {},
    {
      originX:"center",
      originY:"center",
      scaleX:0.8,
      scaleY:0.8,
      left:400,
      top:350,
      selectable: false,
      hoverCursor: 'auto',
      isMask: true
    }
  );

  globalBg = xclipRect;
  cv.clipPath = xclipRect;
  cv.add(xclipRect);
}

const textProperties = ['ladoSeleccionado','angle', 'backgroundColor', 'clipTo', 'fill', 'fillRule', 'flipX', 'flipY', 'fontFamily', 'fontSize', 'fontStyle', 'fontWeight', 'globalCompositeOperation', 'height', 'id', 'left', 'letterSpace', 'lineHeight', 'opacity', 'originX', 'originY', 'path', 'scaleX', 'scaleY', 'shadow', 'stroke', 'strokeDashArray', 'strokeLineCap', 'strokeLineJoin', 'strokeMiterLimit', 'strokeWidth', 'text', 'textAlign', 'textBackgroundColor', 'textDecoration', 'top', 'transformMatrix', 'useNative', 'visible', 'width'];

const imageProperties=  [

  "angle",
  "backgroundColor",
  "cropX",
  "cropY",
  "crossOrigin",
  "fill",
  "fillRule",
  "filters",
  "flipX",
  "flipY",
  "globalCompositeOperation",
  "height",
  "left", 
  "opacity",
  "originX",
  "originY",
  "paintFirst",
  "scaleX",
  "scaleY",
 // "shadow",
  "skewX",
  "skewY",
  "src",
 /* "stroke",
  "strokeDashArray",
  "strokeDashOffset",
  "strokeLineCap",
  "strokeLineJoin",
  "strokeMiterLimit",
  "strokeUniform",
  "strokeWidth", */
  "top",
  "type",
  //"version",
  "visible",
  "width"
]

const setrefFunction=(myprop)=>{

  const mycv = canvas_def;

  if (mycv){

    const act = mycv.getActiveObject();

    //alert(JSON.stringify(act));
    
    if (act){
     
        let proplist = [];

        for(var i=0; i<textProperties.length; i++) {
          var property = textProperties[i];
          proplist={...proplist,[property]: act[property]};
        }

        setref({...proplist,active:true});
      }else{
        setref({active:false})
      }


}

}


const setItemProp=async(prop,value)=>{

  const mycv = canvas;

  if (mycv){
   
    const act = mycv.getActiveObject();
    if (act){
      await act.set({
          fontFamily: value
      });

    let proplist = [];

    for(var i=0; i<textProperties.length; i++) {
      var property = textProperties[i];
      proplist={...proplist,[property]: act[property]};
    }
    
    proplist={...proplist,[prop]: value}

    setref({...proplist,active:true});


    mycv.renderAll();
    }
  }

}




const destroySelected=()=>{

  const mycv = canvas_def;
  let removed = false;

  if (mycv){
    mycv.getActiveObjects().forEach((obj) => {
     if (obj.isEditing == true){
     }else{
      mycv.remove(obj);
      removed = true;
     }
    });
    
    if (removed){
      mycv.discardActiveObject();
      mycv.renderAll();
    }

  }

}

const loadFromSave=async(cv=null)=>{
  let loaded = false;
  const currentEdit = localStorage.getItem("editor");

  if (cv){

  }else{
    cv = canvas;
  }
  /*
  canvas.getObjects().forEach((obj) => {

    if (obj.ladoSeleccionado){
      obj.set({
        visible: obj.ladoSeleccionado == vista
      })
    }

  });*/

  if (currentEdit){

    const editJSON = JSON.parse(currentEdit);


    try {
      if (cv.getObjects()){
        cv.getObjects().forEach((obj)=>{
          cv.remove(obj);
        });
      }
    } catch (error) {
      
    }

  

      let obj_prop = {};
      let proplist = [];

      for (let o in editJSON.objects) {
        proplist = [];  
        obj_prop = editJSON.objects[o];

        let inserted_obj = null;

        if (obj_prop.custom.isMask){
          
          inserted_obj = await AddImage(obj_prop.general.src, {...obj_prop.general,selectable: false,hoverCursor: 'auto',ladoSeleccionado:null,visible:true,isMask:obj_prop.custom.isMask},cv);
          cv.clipPath = inserted_obj;

        }else{

        switch(editJSON.objects[o].general.type){
          case "Textbox":

            for(let i=0; i<textProperties.length; i++) {
              let property = textProperties[i];
              proplist={...proplist,[property]: obj_prop.general[property]};
            }

            inserted_obj = await AddText(obj_prop.general.text,{...proplist,ladoSeleccionado:obj_prop.custom.ladoSeleccionado, visible:obj_prop.custom.ladoSeleccionado==1,selectable:true},cv);
          break;
          case "Image":
            //alert(obj_prop.general.src);

            if (obj_prop.general.src.includes("data:image/png;base64")){
              inserted_obj = await AddImage(obj_prop.general.src,null,cv);
              inserted_obj.set({...obj_prop.general,ladoSeleccionado:obj_prop.custom.ladoSeleccionado, visible:obj_prop.custom.ladoSeleccionado==1,selectable:true});
            }else{
              
            for(let i=0; i<imageProperties.length; i++) {
              let property = imageProperties[i];
              proplist={...proplist,[property]: obj_prop.general[property]};
            }
            inserted_obj = await AddImage(obj_prop.general.src, {...proplist,ladoSeleccionado:obj_prop.custom.ladoSeleccionado, visible:obj_prop.custom.ladoSeleccionado==1,selectable:true},cv);
            }
            
           
          break;
        }}

        
        loaded = true;
      }

      cambiarVista(1,true,cv);
      //canvas.loadFromJSON(currentEdit);
     
    
  }
  cv.renderAll();
  return loaded;

}

  
  useEffect(() => {

    

    const cv = new fabric.Canvas(canvasEl.current);
    let loaded = loadFromSave(cv).then((res)=>{
      if (res == false){
        addMask(cv);
      }
    })


    cv.renderAll();

    canvas_def = cv;
    setcanvas(cv);

cv.on({
  'selection:updated': function(canvas_def,setref) {
    setrefFunction();
  },
  'selection:created': function() {
    setrefFunction();
   },
   'object:modified': function() {
    setrefFunction();
   },
'selection:cleared': function() {
    setrefFunction();
   },
   
});


document.addEventListener('keyup', function (event) {
  // Checking for Backspace. 
  if (event.key === "Backspace") {destroySelected(canvasEl)}
  // Checking for Delete. 
 if (event.key  === "Delete") {destroySelected(canvasEl)}
}); 





    return () => {
      cv.dispose();
    }

  },[canvasEl]);


  const AddText=async(text="Texto",props=null,cv=null)=>{

    if (cv){

    }else{
      cv = canvas;
    }

    let basicprops = {
      text:"Texto",
      left:400,
      top:350,
      fill:"black",
      fontSize:24,
      scaleX:3,
      scaleY:3,
      originX:"center",
      originY:"center",
      ladoSeleccionado:ladoSeleccionado
    }

    let tex = new fabric.Textbox(text,basicprops);

    cv.add(tex);


    if (props){
      tex.set(props);
      //basicprops = props;
    }

    cv.renderAll();

    return tex;

  }

  const AddImage=async(url, props=null,cv)=>{

    let im = null;

    if (cv){

    }else{
      cv = canvas;
    }
    
    let basicprops = {
      ladoSeleccionado:ladoSeleccionado,
      originX:"center",
      originY:"center",
      left:400,
      top:350,
    }

  


    try {
      im = await fabric.FabricImage.fromURL(
        url,
        {},
        basicprops
      );

      //alert(url);

      cv.add(im);

      if (props){
        im.set(props);
      }

    cv.renderAll();
  } catch (error) {
      message.error(error);
  }


    cerrarModal();
    return im;

  }
  
  //const fontList = ["Arial","Times New Roman","Calibri","Helvetica","Futura","Garamond","Verdana","Comic Sans","Georgia","Impact","Bradley Hand","Brush Script MT","Luminari"];

  const fontList = ['Arial',
'Arial Black',
'Bahnschrift',
'Calibri',
'Cambria',
'Cambria Math',
'Candara',
'Comic Sans MS',
'Consolas',
'Constantia',
'Corbel',
'Courier New',
'Ebrima',
'Franklin Gothic Medium',
'Gabriola',
'Gadugi',
'Georgia',
'HoloLens MDL2 Assets',
'Impact',
'Ink Free',
'Javanese Text',
'Leelawadee UI',
'Lucida Console',
'Lucida Sans Unicode',
'Malgun Gothic',
'Marlett',
'Microsoft Himalaya',
'Microsoft JhengHei',
'Microsoft New Tai Lue',
'Microsoft PhagsPa',
'Microsoft Sans Serif',
'Microsoft Tai Le',
'Microsoft YaHei',
'Microsoft Yi Baiti',
'MingLiU-ExtB',
'Mongolian Baiti',
'MS Gothic',
'MV Boli',
'Myanmar Text',
'Nirmala UI',
'Palatino Linotype',
'Segoe MDL2 Assets',
'Segoe Print',
'Segoe Script',
'Segoe UI',
'Segoe UI Historic',
'Segoe UI Emoji',
'Segoe UI Symbol',
'SimSun',
'Sitka',
'Sylfaen',
'Symbol',
'Tahoma',
'Times New Roman',
'Trebuchet MS',
'Verdana',
'Webdings',
'Wingdings',
'Yu Gothic']

  const font_list: MenuProps['items'] = fontList.map((f, i) => {
    return {
      label: <p style={{fontFamily:f}}onClick={()=>setItemProp("fontFamily",f)}>{f}</p>,
      key: f,
    };
  })







const image_manager_options = [
  {
    key: 1,
    label: <p style={{fontSize:"18px"}}> Galeria</p>,
    children: <LoadCatalogo AddImage={AddImage} />
  },
  {
    key: 2,
    label: <p style={{fontSize:"18px"}}> Subir Imagen</p>,
    children: <>
     
<Draggable AddImage={AddImage} />

            
    </>,
  },
  {
    key: 3,
    label: <p style={{fontSize:"18px"}}> Mis Imagenes</p>,
    children: <LoadCatalogo AddImage={AddImage} />,
  },
]

const loadImagemManager =(mydef = 1)=>{

    return [
    <>
    <h2>Imagenes y Patrones</h2>
    <Tabs tabBarGutter={"4vw"} centered style={{width:'100%'}} defaultActiveKey={mydef} items={image_manager_options}  />
    </>
  ];

}

function groupthem (){
  
  var objs = [];
  //get all the objects into an array
  objs = canvas._objects.filter(function(obj){
      return obj;
  });
 
  //group all the objects 
   var alltogetherObj = new fabric.Group(objs,{
              
              originX:'center',
              originY:'center'});


  //clear previous objects
  canvas._objects.forEach(function(obj){
      canvas.remove(obj);
  });
  
  canvas.add(alltogetherObj);
  alltogetherObj.setCoords(); 
  canvas.renderAll();
  canvas.clipPath = null;
  return alltogetherObj;
}

function loadSVGasXML()
{

    const svgDiv = document.getElementById('dwn');
    var SVGFile="/assets/bueno2.svg"
    var loadXML = new XMLHttpRequest;

    function handler(){
        if(loadXML.readyState == 4 &&loadXML.status == 200){
               svgDiv.innerHTML=loadXML.responseText;
               console.log(svgDiv.innerHTML);
        }
    }

    if (loadXML != null){
        loadXML.open("GET", SVGFile, true);
        loadXML.onreadystatechange = handler;
        loadXML.send();
    }
}

const renderImage =async()=> {
  setSavingImage(true);

  let dataitems = [];

  canvas.getObjects().forEach((o) => {
    const itemdata = o;
    dataitems.push({general:o, custom:{ladoSeleccionado:o.ladoSeleccionado, isMask:o.isMask}});
  });

 
  let canvasJSON = canvas.toJSON();

  canvasJSON.objects = {...dataitems};

  localStorage.setItem("editor",JSON.stringify(canvasJSON));


  let preview_url = null;
  let tipo = 2;

  if (tipo==1){
    loadSVGasXML();
  }else{


  const currentVista = ladoSeleccionado;
  
  let img_list={
    1:[],
    2:[]
  };

  for(let i = 1;i<=2;i++){

  cambiarVista(i);

  let img_normal = canvas.toDataURL({
    width: canvas.width,
    height: canvas.height,
    left: 0,
    top: 0,
    format: "png",
    //multiplier: 10
  });

  canvas.clipPath.set({
    visible: false
  })

  // Preview Guide
  let  img_guide = canvas.toDataURL({
    width: canvas.width,
    height: canvas.height,
    left: 0,
    top: 0,
    format: "png",
    multiplier: 10
  });

  canvas.clipPath.set({
    visible: true
  });

  img_list[i]={img_normal, img_guide};

  }

  cambiarVista(currentVista);

  const res= await getFetch("preview/create_preview",{images:img_list},"POST");

  

  if (res){
    if (res.preview_url != ""){

      await delay(2000);
      preview_url = res.preview_url;

      handleAddToCart(res.preview_url);
    }
  }else{

  }

  //console.log(JSON.stringify(img_list));

  // let locfilesrc = URL.createObjectURL(href);
  //const dwn = document.getElementById('dwn');
  //dwn.innerHTML = "<a href=" + img_normal + " download='normal.png'>Normal</a><a href=" + img_list[] + " download='guide.png'>Normal</a>";
  
}

setSavingImage(false);

return preview_url;
}

const delay = ms => new Promise(res => setTimeout(res, ms));

const showImageSelector=(def)=>{
  setmodalProps({...modalProps,
    content: loadImagemManager(def),
    active:true
  });
}

const cerrarModal = () => {
  setmodalProps({...modalProps,active:false});
};

const cambiarVista=(vista,renderall=true,cv=null)=>{

  if (cv){

  }else{
    cv = canvas;
  }

  
  if (cv){
    cv.getObjects().forEach((obj) => {

        if (obj.ladoSeleccionado){
          obj.set({
            visible: obj.ladoSeleccionado == vista
          })
        }

      });

      cv.discardActiveObject();
      if (renderall){cv.renderAll();}
      setladoSeleccionado(vista);
  }

 
}

return (
  <>
  <Spin spinning={SavingImage}>


 
          <Row style={{ paddingTop: "100px" }}>
            <Col span={24} xl={18} style={{ textAlign: "center" }}>
              <Button className='editorMainButtons' onClick={()=>{AddText()}}><span className='bigIcon'><IoText /></span><br/>Texto</Button>
              <Button className='editorMainButtons' onClick={()=>{showImageSelector(1)}}><span className='bigIcon'><FaStar /></span><br/>Elementos</Button>
              <Button className='editorMainButtons' onClick={()=>{showImageSelector(2)}}><span className='bigIcon'><FaFileUpload /></span><br/>Subir Imagen</Button>

              <div className="canvasContent">
                  <canvas  onLoad={()=>{alert("sdsd")}} className="canvasFullWidth"  width={canvasProps.width}  height="600px" ref={canvasEl}/>
              </div>

              <Col span={24}>
                  <Button className={ladoSeleccionado == 1 ? "editorSecButtons sel":"editorSecButtons"} onClick={()=>{cambiarVista(1)}}><span className='bigIcon'><BsFront/></span><br/>Adelante</Button>
                  <Button className={ladoSeleccionado == 2 ? "editorSecButtons sel":"editorSecButtons"} onClick={()=>{cambiarVista(2)}}><span className='bigIcon'><BsBack /></span><br/>Atras</Button>
                  {renderTools()}
                  {modalProps.active == true ? <Modal title={modalProps.title} width={1200} open={modalProps.active} onOk={cerrarModal} onCancel={cerrarModal}>
                    {modalProps.content}
                    </Modal>: null }
              </Col>
              {contextHolder}
            </Col>
           
            <Col  style={{ padding: "2rem" }}>
            {fetchdata.status[endpoint] ? (<>
            
           
              <Rate
                style={{
                  fontSize: "1rem",
                  margin: "0px",
                  padding: "0px",
                  display: "inline",
                  marginInlineEnd: "8px",
                }}
                allowHalf
                defaultValue={2.5}
              />
              <br />
              <br />
              <Slide direction="right" duration={500} triggerOnce={true}>
                <h2>{product_data.p_name}</h2>
                <br />
              </Slide>
              <Slide direction="right" duration={800} triggerOnce={true}>
                <p style={{ lineHeight: "25px" }}>{product_data.p_short_desc}</p>
                <br />
              </Slide>
              <Slide direction="right" duration={1000} triggerOnce={true}>
                <p style={{ fontSize: "2rem" }}>
                  {`$ ${product_data.p_base_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </p>
                <br />
              </Slide>
              <Slide direction="right" duration={1200} triggerOnce={true}>
    
                <Button className="cartButton" onClick={()=>{renderImage()}}>
                  <FaShoppingCart />
                &nbsp;&nbsp;Añadir a Carrito</Button>
                
              </Slide>
              <div id='dwn'></div>
              </> ) : (
              <p style={{ height: "1200px" }}></p>
            )}
                    </Col>
          </Row>
        <Row style={{marginTop:"100px",overflow:"hidden",textAlign:"left",minHeight:"150vh",padding:"10px"}}>

        <Col span = {24} md={2} lg={2} xl={3}></Col>
        <Col span = {24} md={20} lg={20} xl={18}>
          
          <Row style={{ marginTop: "100px" }}>
            <Tabs defaultActiveKey="1" items={items} style={{ width: '100%' }} />
          </Row>
      
        </Col>
        <Col span = {24} md={2} lg={2} xl={3}></Col>
        </Row>
        </Spin>
  </>
);



};







export const LoadCatalogo =({AddImage})=>{

  const dispatch = useDispatch();
  const fetchdata = useSelector((state)=>state.fetchdata);
  const {getFetch} = ApiGlobal("123",[]);

  const [ImagesList,setImagesList] = useState([]);
  const [ImagesListFilter,setImagesListFilter] = useState([]);

  const transformImagesList=(data)=>{
    let newdata = [];
    
    if (data){
      if (data.imagenes){
        data.imagenes.forEach(i => {
          newdata.push({
            nombre: "",
            path: "/assets/images/editor/galeria/"+i.bucket,
            tags:i.tags
          })
        });

        setImagesList(newdata);
        setImagesListFilter(newdata);
      }}

    return newdata;
  }


  //Cargar Imagenes de Galeria
  useEffect(()=>{
    getFetch("editor/imagenes",null,"GET",transformImagesList);
  },[]);



  const options = [
    {
      label:"perros",
      value:"perros"
    },
    {
      label:"gatos",
      value:"gatos"
    },
    {
      label:"objetos",
      value:"objetos"
    },
    {
      label:"patrones",
      value:"patrones"
    },
    {
      label:"elementos",
      value:"elementos"
    }
  ];
  

  function containsAny(arr, str) {
    for (let i = 0; i < arr.length; i++) {
        if (str.includes(arr[i])) {
            return true;
        }
    }
    return false;
}

  const updateImagesFilter =(tags)=>{
  
    dispatch(setitemdata({endpoint:"formEditor", data:{selectedTags:tags}}));
    const newdata = ImagesList.filter((o) => containsAny(tags, o.tags.toLowerCase()));
    setImagesListFilter(newdata);

  }

  let st = [];

  // <p>{im.nombre}</p>

  ImagesListFilter.forEach((im) => {
    st.push(
      <>
          <a role="button" className='cardCatalogo' onClick={()=>{AddImage(im.path)}}>
            <img src={im.path} />
          </a>
      </>
  );
  });


  const html = [
 
    <Row gutter={20}>
      <Col span={24}>
      <p style={{lineHeight:"25px"}}>Buscar Imagenes:</p>
      <Select
          mode="tags"
          value={fetchdata.item.formEditor.selectedTags}
          size={10}
          placeholder="Palabras clave"
          defaultValue={[]}
          onChange={updateImagesFilter}
          style={{ width: '100%' }}
          options={options}
        />
      </Col>
      <Col span={24} style={{marginTop:"30px"}}>{st}</Col>
    </Row>
  ];

  return html;


}


export default Editor;
