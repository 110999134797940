export class Poster{

    async post(endpoint, method, data, tokenheader = null){
        const location = window.location.hostname;
        let settings = {
            method: method,
            headers: {'content-type': 'application/json','x-auth-token':tokenheader},
        };

        if (method == "POST"){
            settings.body=JSON.stringify(data);
        }

        try {
            const fetchResponse = await fetch(`${process.env.REACT_APP_API+endpoint}`, settings);
            const data = await fetchResponse.json();
            return data;
        } catch (e) {
            return e;
        }
    }

}