import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router";
import { Button, Tabs, Rate, Col, Row } from 'antd';
import { FaShoppingCart } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from 'react-redux';
import { ApiGlobal } from '../hooks/ApiGlobal';
import { addItem } from '../redux/cartSlice';

import { Editor } from "./Editor.jsx";

const Producto = () => {

  const dispatch = useDispatch();
  const fetchdata = useSelector((state)=>state.fetchdata);
  const cart = useSelector((state)=>state.cart);
  
  const { id } = useParams();
  const endpoint = "producto/"+id;

  const {getFetch} = ApiGlobal("123",[endpoint]);

  let product_data = fetchdata.fetch[endpoint] ? fetchdata.fetch[endpoint].data:[];

  useEffect(()=>{
    getFetch(endpoint);
  },[]);


  const addProduct = () =>{

  }

  const navigate = useNavigate();


  const handleAddToCart = () => {
    const product_cart = {
        id: product_data.id,
        p_name: product_data.p_name,
        p_base_price: product_data.p_base_price,
        p_shipping_price_base: product_data.p_shipping_price_base,
        tipo: product_data.tipo,
        p_short_desc: product_data.p_short_desc
    }
    dispatch(addItem({...product_cart, image: fetchdata.fetch[endpoint].images[0].thumbnail, preview_design: "", unique: false }));
    navigate("/cart");
  };




  const items = [
    {
      key: '1',
      label: 'Descripción',
      children: fetchdata.status[endpoint] ? product_data.p_long_desc : <></>,
    },
    {
      key: '2',
      label: 'Información extra',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Valoraciones',
      children: 'Content of Tab Pane 3',
    },
  ];

  return (
    <>

      {fetchdata.status[endpoint] ? (

          <>
            <Row style={{ paddingTop: "200px" }}>
              <Col span={24} md={12} style={{ textAlign: "center" }}>
              {
                <ImageGallery
                  showPlayButton={false}
                  showFullscreenButton={false}
                  autoPlay={false}
                  thumbnailPosition="left"
                  items={fetchdata.fetch[endpoint].images}
                />
              }
              </Col>
             
              <Col span={24} md={12} style={{ padding: "2rem" }}>
                <Rate
                  style={{
                    fontSize: "1rem",
                    margin: "0px",
                    padding: "0px",
                    display: "inline",
                    marginInlineEnd: "8px",
                  }}
                  allowHalf
                  defaultValue={2.5}
                />
                <br />
                <br />
                <Slide direction="right" duration={500} triggerOnce={true}>
                  <h2>{product_data.p_name}</h2>
                  <br />
                </Slide>
                <Slide direction="right" duration={800} triggerOnce={true}>
                  <p style={{ lineHeight: "25px" }}>{product_data.p_short_desc}</p>
                  <br />
                </Slide>
                <Slide direction="right" duration={1000} triggerOnce={true}>
                  <p style={{ fontSize: "2rem" }}>
                    {`$ ${product_data.p_base_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </p>
                  <br />
                </Slide>
                <Slide direction="right" duration={1200} triggerOnce={true}>
                  <Button className="cartButton" type="primary" onClick={handleAddToCart}>
                    <FaShoppingCart />
                    &nbsp;&nbsp;Añadir a Carrito
                  </Button>
                </Slide>
              </Col>
            </Row>
            <Row style={{ marginTop: "100px" }}>
              <Tabs defaultActiveKey="1" items={items} style={{ width: '100%' }} />
            </Row>
          </>
        ) : (
        <p style={{ height: "1200px" }}></p>
      )}

    </>
  );
};

export default Producto;
