import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa6";
import { Button, Divider, Form,Input} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { GoogleLogin } from '@react-oauth/google';
import { useSelector, useDispatch } from "react-redux";
import { AuthManager } from "../hooks/AuthManager";

//AuthData={userdata} ErrorAuth={dataState.ErrorAuth} getLogin={dataState.getLogin}  logout={logout} 




const Login = () => {

  const {
    getLogin,
    logout,
    register,
  } = AuthManager();

  
  const userdata = useSelector((state)=>state.user);
  const dispatch = useDispatch();
  //const auth= AuthData;

  const navigate = useNavigate();


const [user, setUser] = useState({
    u_email: "",
    u_pass: "",
  });
  
  useEffect(() => {
    if (userdata.token) {
     // navigate("/cart");
    }
  }, [userdata.token, navigate]);

  const handleSubmit = async(e) => {

    const resp = await getLogin(user);

    if (resp){
      window.location.reload();
    }


   
  };

  return (
    <>
    {
      userdata.token ? (
        <>
        <div style={{padding:"1rem" ,width:"100%",textAlign:"center"}}>
          <p>Haz iniciado sesión como:</p><br/>
          <p><b>{userdata.u_name}</b></p>
          <p>{userdata.u_email}</p>
        </div>
        </>
      ): (
        <>
            <Form  onFinish={handleSubmit} >
              <h2>Iniciar sesión</h2>
              <br/>
              <p style={{lineHeight:"15px"}}>Correo</p>
              <Form.Item
                name="email"
                rules={[{ type: 'email', required: true, message: 'Ingresa un correo valido' }]}
                onChange={(e) => setUser({ ...user, u_email: e.target.value })}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} />
              </Form.Item>
  

              <p style={{lineHeight:"15px"}}>Contraseña</p>
              <Form.Item
                name="pass"
                rules={[{ required: true, message: 'Ingresa una contraseña' },{ min:6, message: 'La contraseña debe tener minimo 6 caracteres' }]}
                onChange={(e) => setUser({ ...user, u_pass: e.target.value })}
              >
                <Input type="password" prefix={<LockOutlined className="site-form-item-icon" />} />
              </Form.Item>
              <div style={{justifyContent:"right",alignItems:"right",display:"flex"}}>
              <Button htmlType="submit" block type="primary">
              Iniciar Sesion
              </Button>
              </div>
              <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}><br/><br/><br/>
              {/*ErrorAuth != "" ? (<p style={{color:"red"}}>{ErrorAuth}</p>):(null) */}
              </div>
             
          
            </Form>
            <h3>Inicia Sesión con Google</h3>
            <br/>
            <GoogleLogin
              useOneTap
     
    onSuccess={async(credentialResponse) => {

      if (credentialResponse.credential){
      const data_login = {
        u_email: "",
        u_pass: "",
        credentialResponse:credentialResponse,
        logintype:2
      }
      
      const rep_google = await getLogin(data_login);
      
      if (rep_google){
        window.location.reload();
      }
    
    }else{
        console.log("Inicio de Sesion Fallida");
      }

    }}
    onError={() => {
      console.log('Login Failed');
    }}
  />

<p style={{ marginTop:"50px", color:"rgba(20,20,20,1)"}}>¿No tienes cuenta aún?</p>
<Button block type="default" href="/register" style={{ marginTop:"10px"}}><FaUserPlus/> Registrarse</Button>
        </>
      )
    }

    </>
  );
};


export default Login;
