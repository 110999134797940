import {createSlice} from "@reduxjs/toolkit";

function isNumber(value) {
    return typeof value === 'number';
}

const initialState = {
    products:[],
    cantidad:[],
    subtotal:[],
    total:[],
    options:[]
}


export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {

        //Información default de carrito(localstorage)
        setCart: (state, action) =>{
            const cartdata = action.payload;
            state.products = cartdata.products;
            state.cantidad = cartdata.cantidad;
            state.subtotal = cartdata.subtotal;
            state.costo_envio = cartdata.costo_envio;
            state.descuento = cartdata.descuento;
            state.total = cartdata.total;
            state.options = cartdata.options;
        },

        //Añadir Producto al Carrito
        addItem: (state, action) =>{
            const product = action.payload; //Información de producto

            let esnuevo = true;
            let cantidad = 0;
            let total_monto = 0;
            let invalid = false;

            state.products = state.products.map((p, idx) =>{

                if (p.id == product.id && p.unique == false){ //Si el producto ya esta en el carrito, añadir la cuenta en +1
                    p.cantidad += 1;
                    esnuevo = false;
                }

                if (!isNumber(p.id) || !isNumber(p.p_base_price) || !isNumber(p.cantidad)){
                    invalid = true;
                    return [];
                }else{
                    cantidad+=p.cantidad;
                    total_monto+=p.cantidad*p.p_base_price;
                    return p;
                }

            });

            if (invalid == true){
                state.products = [];
            }

            if (esnuevo == true){
                state.products = [...state.products, {...product, cantidad: 1}]; //Añadir producto
                cantidad += 1;
                total_monto += product.p_base_price;
            }

            let costo_envio= 80;
            let descuento= 0;

            if (cantidad>=2){
                costo_envio = 0;
    
                if (cantidad>=3){
                    descuento = 0.25;
                }
            }

            state.cantidad = cantidad;
            state.subtotal = total_monto;
            state.costo_envio = costo_envio;
            state.descuento = descuento;
            state.total = total_monto*(1-descuento)+costo_envio;

            localStorage.setItem("cart", JSON.stringify(state));
        },

        changeItem (state, action) {
      
            let product_found = false;
            let cantidad = 0;
            let total_monto = 0;
            let product_index = -1;

            const {index,count,removeall} = action.payload;
             
            let product_removeall = removeall;
    
            if (state.products[0]){
    
                state.products = state.products.map((p, idx) =>{
    
                    if (idx == index){
                        product_found = true;
    
                        if (removeall){
                            p.cantidad = 0;
                        }else{
                            p.cantidad += count;
                        }
    
                        product_index = idx;
                    }
    
                    cantidad+=p.cantidad;
                    total_monto+=p.cantidad*p.p_base_price;
    
                    if (p.cantidad <=0 ){
                        product_removeall = true;
                    }
    
                    return p;
                });
    
                if (product_removeall){
                    state.products = state.products.filter((o, i) => product_index !== i);
                }
              
                let costo_envio= 80;
                let descuento= 0;
    
                if (cantidad>=2){
                    costo_envio = 0;
        
                    if (cantidad>=3){
                        descuento = 0.25;
                    }
                }
    
                state.cantidad = cantidad;
                state.subtotal = total_monto;
                state.costo_envio = costo_envio;
                state.descuento = descuento;
                state.total = total_monto*(1-descuento)+costo_envio;
    
                localStorage.setItem("cart", JSON.stringify(state));
    
            }
        },
        destroyCart(state, action){
            state.products = [];
            state.cantidad = 0;
            state.subtotal = 0;
            state.costo_envio = 0;
            state.total = 0;

            localStorage.setItem("cart", JSON.stringify(state));
    
        }


    }
});

export const {addItem, setCart, changeItem,destroyCart} = cartSlice.actions;
export default cartSlice.reducer;