import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: {
        name: "",
        fullname: "",
        email: "",
        logged: false
    },
    status:false,
    auth_token:"",
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action) =>{
            const {name, fullname, email, logged} = action.payload;
            state.data.name = name;
            state.data.fullname = fullname;
            state.data.email = email;
            state.data.logged = logged;
        },
        setauthloading: (state, action) =>{
            state.status = action.payload;
        },
        setauthtoken: (state, action) =>{
            state.auth_token = action.payload;
        }
    }
});

export const {setauthtoken,setUser, setauthloading} = userSlice.actions;
export default userSlice.reducer;