import React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal,Row,Col,Divider,Card, Progress, Image } from 'antd';
import { MdCancel } from "react-icons/md";
import { FaCreditCard } from "react-icons/fa";
//import Login from "../components/auth/Login";
import { Fade, Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import {changeItem,destroyCart} from "../redux/cartSlice"
//import TempAccountCreator from "../TempAccountCreator";
import { MdLocalShipping } from "react-icons/md";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaGift } from "react-icons/fa6";
import {ApiGlobal} from "../hooks/ApiGlobal";
import { FaEye } from "react-icons/fa";

const Cart = () => {
  const api_url = process.env.REACT_APP_API+"preview/";
  const userdata = useSelector((state)=>state.user);
  const {getFetch} = ApiGlobal(userdata.auth_token,["stripe/create-checkout-session"]);
  const cart = useSelector((state)=>state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ModalContent, setModalContent] = useState(<></>);
  function currencyFormat(num) {
    if (typeof num === 'number'){
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }else{
      return '-';
    }
  }
  
  const proceder_compra = async() =>{

    const resumelist = cart.products.map((p, idx) =>{
      return {
        c:p.cantidad,
        id:p.id,
        props:JSON.stringify({
          preview_design:p.preview_design,
          tipo:p.tipo
        })
      }
    });


    const response = await getFetch("stripe/create-checkout-session",{cartItems:resumelist},"POST");
  
    if (response.url){
      window.location.href = response.url;
    }else{
      console.log("Error al crear session")
    }

  };


  const handleAddToCart = (product,index) => {
    dispatch(changeItem({index:index,count:1,removeall: false}));
  };
  const handleDecreaseCart = (index) => {
    dispatch(changeItem({index:index,count:-1,removeall: false}));
  };
  const handleRemoveFromCart = (index) => {
    dispatch(changeItem({index:index,count:-1,removeall: true}));
  };
  const handleClearCart = () => {
    dispatch(destroyCart());
  };

  //handleClearCart();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getoferta_mensaje=(c)=>{

    let msg = "";
    switch(c){
      case 1:
        msg = "¡Compra 1 articulo más y obtén tu envío gratis!";
      break;
      case 2:
        msg = "¡Compra 1 articulo más y recibe un 25% de descuento adicional!";
      break;
      case 3:
        msg = "¡Compra 1 articulo más y recibe un regalo sorpresa!";
      break;
      default:
        msg = "¡Haz conseguigo un regalo sorpresa con tu compra!"
    }
    return msg;
  }


  const showModalPreview = (url) => {


    setModalContent(<>
            <Row>
            <Col span={24} lg={12} style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
            <div style={{textAlign:"center",padding:"10px"}}>
            <p style={{fontSize:"22px"}}>Frente</p>
              <Image src={url+"/1"} />
            </div>
            </Col>
            <Col span={24} lg={12}>
            <div style={{textAlign:"center",padding:"10px"}}>
            <p style={{fontSize:"22px"}}>Atras</p>
            <Image src={url+"/2"} />
            </div>
            </Col>
        </Row>
    </>)
    setIsModalOpen(true);
  };


  
  return (
  <div className="cart-container">

{userdata.logged == false ? (
  <Modal style={{maxWidth:"98%"}} width="1200px" title="" footer ="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {userdata.logged ? (<></>): ({ /*<><TempAccountCreator dataState={dataState} /></>*/})}
  </Modal>):(null)
}

<h2>Mis Articulos</h2>
{cart.products.length === 0 ? (
<div className="cart-empty">
<p>Carrito vacio</p>
<div className="start-shopping">
<Link to="/">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    className="bi bi-arrow-left"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
    />
  </svg>
  <span>Ir a Tienda</span>
</Link>
</div>
</div>
) : (
<div>

<Row>
<Col span={12}>
<Button onClick={() => handleClearCart()}>
Vaciar carrito
</Button>
</Col>
<Col span={12} style={{marginTop:"10px",textAlign:"right"}}>
    <a href="/" style={{fontSize:"1rem"}}>Explorar más articulos</a><br/><br/><br/>
</Col>
</Row>

<Col span={0} lg={24} >
  <Row gutter={30} style={{textAlign:"left",padding:"5px", backgroundColor:"#e78500",color:"white",marginBottom:"20px", borderRadius:"10px 10px 0px 0px"}}>
    <Col span={24} lg={4} style={{fontSize:"16px", fontWeight:"bold"}}></Col>
    <Col span={24} lg={8} style={{fontSize:"16px", fontWeight:"bold"}}>Producto</Col>
    <Col span={24} lg={3} style={{fontSize:"16px", fontWeight:"bold"}}>Precio</Col>
    <Col span={24} lg={5} style={{fontSize:"16px", fontWeight:"bold",paddingLeft:"30px"}}>Cantidad</Col>
    <Col span={24} lg={4} style={{fontSize:"16px", fontWeight:"bold", textAlign:"right"}}>Total</Col>
  </Row>
</Col>

<div className="cart-items">
{cart.products &&
  cart.products.map((cartItem,index) => {

   return <>

    <Row gutter={30}>

    <Col span={8} sm={6} lg={4}>
      {cartItem.preview_design !="" ? (<div style={{textAlign:"center"}}><img style={{height:"120px"}} src={process.env.REACT_APP_API+"preview/"+cartItem.preview_design+"/1"} alt={cartItem.name} /><Button onClick={()=>{showModalPreview(api_url+cartItem.preview_design)}}>Preview<FaEye /></Button></div>):(<img style={{height:"120px"}} src={cartItem.image} alt={cartItem.name} />)}
    </Col>
    <Col span={15}  lg={8} tyle={{padding:"0px", border:"0px", textAlign:"left"}}>
      <Row>
      <Col span={24} lg={24}>
      <p style={{fontSize:"1rem",margin:"0px"}}>{cartItem.p_name}</p>
         <br/>
        </Col>
          <Col span={0} lg={12}>
          <Button onClick={() => handleRemoveFromCart(index)}>
            Remover
          </Button>
          </Col>
          <Col span={24} lg={0} style={{padding:"0px", border:"0px"}}>
          <p style={{fontSize:"1.1rem", color:"rgb(22,22,22)"}}>{currencyFormat(cartItem.p_base_price * cartItem.cantidad)}</p>
          <p style={{fontSize:"0.8rem", color:"gray"}}>{currencyFormat(cartItem.p_base_price)} x {cartItem.cantidad}</p>
            <span style={{display:"flex",padding:"10px"}}>
              <Button onClick={() => handleDecreaseCart(index)}>-</Button>
              <div style={{paddingLeft:"1.1rem",paddingRight:"1.1rem",paddingTop:"0.5rem"}}>{cartItem.cantidad}</div>
              <Button onClick={()=>handleAddToCart(cartItem,index)} >+</Button>
            </span>
          </Col>
          </Row>
    </Col>
    <Col span={0} lg={3}>

    <p style={{fontSize:"1.2rem",paddingTop:"0.3rem"}}>{currencyFormat(cartItem.p_base_price)}</p>
    </Col>
    <Col span={0} lg={5} >
        <span style={{display:"flex"}}>
          <Button onClick={() => handleDecreaseCart(index)}>-</Button>
          <div style={{paddingLeft:"1.1rem",paddingRight:"1.1rem",paddingTop:"0.5rem"}}>{cartItem.cantidad}</div>
          <Button onClick={() => handleAddToCart(cartItem,index)}>+</Button>
        </span>
    </Col>
    <Col span={0} lg={4} style={{paddingTop:"0rem",margin:"0px"}}>

    <p style={{fontSize:"1.2rem",textAlign:"right",paddingTop:"0rem",margin:"0px"}}>{currencyFormat(cartItem.p_base_price * cartItem.cantidad)}</p>
    </Col>

  </Row>
 
  <Divider/>

   </>


  

    
})
  
  
  }
</div>
<Row>
  <Col span={24} md={24} lg={14} >

  <p style={{color:"green",fontSize:"20px"}}><b>{getoferta_mensaje(cart.cantidad)}</b></p>
  <br/><br/><br/><br/>
  <Row style={{minHeight:"300px",color:"#8C1D66"}}>
    <Col span={24} lg={20}>
    <div style={{width:"100%"}}>
      <p style={{position:"absolute", left:"45%",textAlign:"center",marginTop:"-50px", color: cart.cantidad >=3 ? "#DEC420": "#8C1D66"}}><span style={{fontSize:"32px"}}><RiDiscountPercentFill /></span><br/>+25% Descuento<br/><span style={{fontSize:"11px"}}>(3+ articulos)</span></p>
    </div><br/>
    <Progress percent={Math.min(Math.min(4,cart.cantidad)*20+(cart.cantidad-1)*10,100)} status="active" strokeColor={{ from: '#108ee9', to: '#0EFF03' }} /><br/>
    <div style={{width:"100%"}}>
      <p style={{position:"absolute", left:"20%",textAlign:"center", color: cart.cantidad >=2 ? "#DEC420": "#8C1D66"}}>Envío Gratis<br/><span style={{fontSize:"11px"}}>(2+ articulos)</span><br/><span style={{fontSize:"32px"}}><MdLocalShipping /></span></p>
      <p style={{position:"absolute", left:"80%",textAlign:"center", color: cart.cantidad >=4 ? "#DEC420": "#8C1D66"}}>Regalo Sorpresa<br/><span style={{fontSize:"11px"}}>(4+ articulos)</span><br/><span style={{fontSize:"28px"}}><FaGift /></span></p>
    </div>
    <br/>
    </Col>
  </Row>
  {
  /*
    <Row gutter={10} style={{paddingBottom:"50px"}}>
      <Col span={12} lg={11}>
        <Card style={{height:"100%"}}>
        <h3>Compra 2 productos o más</h3>
        <Divider/>
        <p>Envío Gratis</p>
        </Card>
      </Col>
      <Col span={12} lg={11}>
        <Card style={{height:"100%"}}>
        <h3>Compra 3 productos o más</h3>
        <Divider/>
        <p>Envío Gratis</p>
        <p>Descuento Adicional del 25%</p>
        </Card>
      </Col>
    </Row>
*/
  }
  </Col>

  <Col span={24} lg={10} style={{textAlign:"right"}} >
  <Slide duration={500} triggerOnce={true}> 
 

<Row>
  <Col span={10}>
  <p style={{fontSize:"16px",textAlign:"left"}}><b>Subtotal:</b></p>
  </Col>
  <Col span={14}>
  <p style={{fontSize:"16px"}}>{currencyFormat(cart.subtotal)}</p><br/>
  </Col>
</Row>

<Row>
  <Col span={10}>
  <p style={{fontSize:"16px",textAlign:"left"}}><b>Envío:</b></p>
  </Col>
  <Col span={14}>
  {cart.costo_envio ? <p style={{fontSize:"16px"}}>{"+"+currencyFormat(80)}</p>:(<p style={{fontSize:"16px"}}><span style={{color:"green"}}>GRATIS </span><br/><i style={{color:"gray",fontSize:"12px"}}>(ahorro: $80)</i></p>)}<br/>
  </Col>
</Row>
{
cart.descuento ? <Row>
  <Col span={10}>
  <p style={{fontSize:"16px",textAlign:"left"}}><b>Descuento:</b></p>
  </Col>
  <Col span={14}>
   <p style={{fontSize:"16px",color:"green"}}>{  "-"+currencyFormat(cart.descuento*cart.subtotal) + " ( "+(cart.descuento*100).toFixed(2)+"% )" }</p>
  </Col>
</Row>:null

}
<Row>
  <Divider/>
  <Col span={12}>
  <p style={{fontSize:"22px",textAlign:"left"}}><b>Total:</b></p>
  </Col>
  <Col span={12}>
  <p style={{fontSize:"28px"}}>{currencyFormat(cart.total)}</p><br/>
  </Col>
</Row>

        {userdata.data.logged ? (<>
          <Button
          style={{fontSize:"1.2rem",height:"3rem",paddingRight:"50px",backgroundColor:"#432bee"}}
          className="cartButton"
          onClick={proceder_compra}> &nbsp;&nbsp;Proceder con la Compra<span style={{marginTop:"9px"}}><FaCreditCard /></span></Button>
          </>
        ) : (
          <Button
            style={{fontSize:"1.2rem",height:"3rem",paddingRight:"50px"}}
            type="primary"
            className="cart-login"
            onClick={showModal}
          >
            &nbsp;&nbsp;Iniciar sesion y comprar&nbsp;<span style={{marginTop:"9px"}}><FaCreditCard /></span>
          </Button>
        )}
    
     
      </Slide> 
  </Col>
  <Modal width="1200px" style={{ maxWidth:"98%"}} title="" closeIcon={<a style={{fontSize:"23px"}}><MdCancel /></a>} open={isModalOpen} footer={<></>} onOk={()=>{ setIsModalOpen(false);}} onCancel={()=>{ setIsModalOpen(false);}}>
        {ModalContent}
        </Modal>

</Row>



</div>
)}
</div>
);



  };
  
  export default Cart;
  