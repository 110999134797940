import logo from './logo.svg';
import './App.css';
import Home from './pages/Home.jsx';
import Cuenta from './pages/Cuenta.jsx';
import Template from './Template.jsx';
import { setCart } from './redux/cartSlice.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import Producto from "./pages/Producto.jsx";
import Cart from './pages/Cart.jsx';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {AuthManager} from './hooks/AuthManager.js';
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "./pages/Editor.jsx";

function App() {

  const {getuserdata} = AuthManager();
  const dispatch = useDispatch();
  const userdata = useSelector((state)=>state.user);


  // Cargar Items de Carrito del Locar Storage
  useEffect(()=>{

    const cartlocal = JSON.parse(localStorage.getItem('cart'));
    if (cartlocal){
    if (cartlocal.products[0]){
      dispatch(setCart(cartlocal));
    }}

    const usertoken = localStorage.getItem('tokenses');
    if (usertoken){
      getuserdata(usertoken);
    }

  },[])

  return (
    <div className="App">

<GoogleOAuthProvider clientId="375166713351-9svlls504ep56ooms5k0716r5a6dkqj0.apps.googleusercontent.com">
    <BrowserRouter>
         
            {userdata.status == false ? (
              <Routes>
              <Route path="/" element={<Template content={<Home />} />}  />
              <Route path="/producto/:id" element={<Template content={<Producto/>} margintop={"0px"} />}  />
              <Route path="/cart" element={<Template content={<Cart/>} />}  />
              <Route path="/cuenta" element={<Template content={<Cuenta/>} />}  />
              <Route path="/editor/:id" element={<Template content={<Editor/>} notborder={true} />}  />
              
              <Route index element={<Template content={<Home />} />}  />
              <Route path="*" element={<Template content={<pageNotFound />} />}  />
              </Routes>
            ):null
            }
          
    </BrowserRouter>
    </GoogleOAuthProvider>

    </div>
  );
}

export default App;
