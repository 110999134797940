import { useState, useEffect, useRef} from 'react';
import dayjs from 'dayjs';
import {setfetchdata, setitemdata, setstatus} from '../redux/fetchSlice.js';
import { useDispatch } from 'react-redux';
import { Poster } from './Poster.js';
const hoy = dayjs();
const hoy_1mesdespues = hoy.add(1, 'month');

const poster= new Poster();

export function ApiGlobal(token=null, listas = []){

    const dispatch = useDispatch();

    //const {getDefaultFormValues} = FormValues();

    let default_listas_data = [];
    let default_listas_loading = [];
    let default_listas_error = [];
    let default_form_values = [];
    let formactions = [];
    
    useEffect(()=>{
        listas.forEach(key => {
            default_listas_data[key]=[];
            default_listas_loading[key]=false;
            default_listas_error[key]=false;
    
           // default_form_values[key]= getDefaultFormValues(key);
            formactions[key]=[];
    
            dispatch(setfetchdata({endpoint:key, data:[]}));
            dispatch(setitemdata({endpoint:key, data:[]}));
            dispatch(setstatus({endpoint:key, data:false}));
        });
    },[])
    


    
    //dispatch(setstatus(default_listas_loading));
    //dispatch(setitemdata([]));

    /*
    const setForm_in =(tag,nom,v)=>{
        if (tag){
        let change_def = {...FormData,[tag]:{...FormData[tag],[nom]:v}}
        setFormData(change_def);
        }
    }


    const loadFormDataApi = async(endpoint, id = null, transform_function = null)=>{

        let change_def = {...FormData,[endpoint]:[]}
        setFormData(change_def);

        const params = {
            id: id
        }

        const data = await getFetch(endpoint, params, transform_function);
        

         change_def = {...FormData,[endpoint]:data[endpoint]}
        setFormData(change_def);
    }
*/

    const getFetch = async(endpoint, params=null, method = "GET", transform_function = null)=>{

        try{
            dispatch(setstatus({endpoint:endpoint, data:false}));
            dispatch(setfetchdata(endpoint,[]));

            const response = await poster.post(endpoint,method,params,token);

            
                if (response){

                    let raw_data = response;

                    if (transform_function != null){
                        raw_data = transform_function(raw_data);
                    }
                   
                    dispatch(setfetchdata({endpoint:endpoint,data:raw_data}));
                    dispatch(setstatus({endpoint:endpoint, data:true}));

                    return raw_data;
                }else{
                    return false;
                }
           
        }catch(error){
            //setErrorFetch({...ErrorFetch,[endpoint]:error});
            dispatch(setstatus({endpoint:endpoint, data:true}));
        } finally{
            dispatch(setstatus({endpoint:endpoint, data:true}));
        }
    }
    

    
    return {
        getFetch,
    }
}