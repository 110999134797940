import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa6";
import { Button, Divider, Form,Input,Upload,Col,Row} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
//AuthData={userdata} ErrorAuth={dataState.ErrorAuth} getLogin={dataState.getLogin}  logout={logout} 




const Draggable = ({AddImage}) => {

  const [Preview, setPreview] = useState(null);
  
  const { Dragger } = Upload;


  const uploadImagePreview=()=>{
    AddImage(Preview.data,{scaleY:Preview.scale,scaleX:Preview.scale});
  }


  const setPreviewUpload = (data) =>{
    

    if (data.file){
    const reader = new FileReader();
    reader.readAsDataURL(data.file.originFileObj);
    reader.onload = () => {

      let i = new Image();
      const imageData = reader.result;

      i.onload = function(){
        const scale = 300/i.height;
        setPreview({data:imageData, scale: scale});
      };
      i.src = imageData;

    };
    reader.onerror = (error) => console.log(error);
    }
  

};

  return (
    <>
   
   { Preview ? <>

        <Row gutter={20}>
          <Col span={24} xl={14}>
          <img src={Preview.data} style={{width:"80%",}} />
          </Col>
          <Col span={24} xl={10}>
          
          <Button block type="primary" onClick={()=>{uploadImagePreview()}} style={{margin:"5px"}}>Cargar Imagen</Button>
          <Button block onClick={()=>{setPreview(null)}} type="primary" style={{color:"white", backgroundColor:"red",margin:"5px"}} >Cancelar</Button>
       
          </Col>
        </Row>
       
       

      </>: <Dragger
              multiple={false}
              accept= '.png, .jpg, .jpeg,.svg'
              name="archivo"
              maxCount={1}
              onChange={setPreviewUpload}
              listType="picture-card"
            >
           <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Haz clic aquí o arrastra para subir tu imagen</p>
          <p className="ant-upload-hint">
            formatos permitidos: png, jpg, jpeg y svg<br/>tamaño maximo: 100MB
          </p>
            </Dragger>
}
    </>
  );
};


export default Draggable;
