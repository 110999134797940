import {createSlice} from "@reduxjs/toolkit";
import { useState } from "react";

const initialState = {
    fetch:[],
    status:[],
    item:{
        formEditor:{
            selectedTags: []
        }
    },
}

export const fetchSlice = createSlice({
    name: "fetch",
    initialState,
    reducers: {

        setfetchdata: (state, action) =>{
            const {endpoint, data} = action.payload;
            console.log(data);
            state.fetch = {...state.fetch, [endpoint]:data}
        },
        setitemdata: (state, action) =>{
            const {endpoint, data} = action.payload;
            state.item = {...state.item, [endpoint]:data}
        },
        setstatus: (state, action) =>{
            const {endpoint, data} = action.payload;
            state.status = {...state.status, [endpoint]:data}
        }

    }
});

export const {setfetchdata, setitemdata, setstatus} = fetchSlice.actions;
export default fetchSlice.reducer;