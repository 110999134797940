import React from 'react';

const Perfil = () => {
    return (
      <div className="not-found">
        <h2>Perfil</h2>
        <p>Info....</p>
      </div>
    );
  };
  
  export default Perfil;
  