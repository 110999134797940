import React from "react";
import NavBar from "./components/NavBar.jsx";
import {Col,Row} from "antd";


const Template =({content,margintop = "100px",notborder=false})=>{
  return (
    <div>
      <Row>
      <NavBar/>
      </Row>
      {
        (notborder == true) ? <Row style={{marginTop:margintop,overflow:"hidden",textAlign:"left",minHeight:"150vh",padding:"10px"}}><Col span = {24}>{content}</Col></Row> :
        <Row style={{marginTop:margintop,overflow:"hidden",textAlign:"left",minHeight:"150vh",padding:"10px"}}>
        <Col span = {24} md={2} lg={2} xl={3}></Col>
        <Col span = {24} md={20} lg={20} xl={18}>{content}</Col>
        <Col span = {24} md={2} lg={2} xl={3}></Col>
      </Row>
      }

    </div>
  );
}

export default Template;
  